<template>
  <div>
    <div v-for="(element, index) in moduleList" :key="index">
      <!-- PC端 -->
      <!-- <moduleHead :module="element" v-if="element.type == 201" /> -->
      <modlueBanner :module="element" v-if="element.type == 202" />
      <modlueGoods :module="element" v-if="element.type == 204" />
      <moduleRecommend :module="element" v-if="element.type == 205" />
      <modlueNotice :module="element" v-if="element.type == 206" />
      <!-- <modlueFooter :module="element" v-if="element.type == 207" /> -->
      <modlueGuangGao :module="element" v-if="element.type == 208" />
    </div>
  </div>
</template>

<script>
import moduleHead from "./module/header/common.vue";
import moduleRecommend from "./module/recommend/common.vue";
import modlueGoods from "./module/goods/common.vue";
import modlueBanner from "./module/banner/common.vue";
import modlueNotice from "./module/notice/common.vue";
import modlueFooter from "./module/footer/common.vue";
import modlueGuangGao from "./module/guanggao/common.vue";

export default {
  name: "Module",
  props: {
    moduleList: { type: Array },
    prev: { default: false }
  },
  components: {
    moduleHead,
    moduleRecommend,
    modlueGoods,
    modlueBanner,
    modlueNotice,
    modlueFooter,
    modlueGuangGao
  },
  data() {
    return {
      placeholder: false,
      selectIndex: undefined, //选中
      activeIndex: undefined, //当前活动active
      pageHeight: undefined
    };
  },

  mounted() {
    this.pageHeight = window.innerHeight;
  },
  methods: {
    //模块点击
    moduleClick(index) {
      this.selectIndex = index;

      this.activeIndex = index;

      this.$emit("moduleClick", index);
    },

    //鼠标移入模块
    mouseovers(index) {
      let top = event.target.getBoundingClientRect().top;
      this.$emit("moduleMouseovers", { top: top, index: index });
    },

    //鼠标离开模块
    mouseout() {
      this.$emit("moduleMouseout");
    },

    delectModule() {
      this.selectIndex = undefined;
      this.moduleList.splice(this.activeIndex, 1);
      this.$emit("deleteMouseout");
    },

    //上移
    up() {
      //移动
      this.moduleList.splice(
        this.activeIndex - 1,
        0,
        this.moduleList[this.activeIndex]
      );

      //删除原来的
      this.moduleList.splice(this.activeIndex + 1, 1);

      this.selectIndex = this.activeIndex - 1;

      this.activeIndex = this.activeIndex - 1;

      this.$forceUpdate();
    },

    //下移
    down() {
      this.moduleList.splice(
        this.activeIndex + 2,
        0,
        this.moduleList[this.activeIndex]
      );
      this.moduleList.splice(this.activeIndex, 1);

      this.selectIndex = this.activeIndex + 1;

      this.activeIndex = this.activeIndex + 1;

      this.$forceUpdate();
    }
  }
};
</script>
<style>
.putItHere {
  height: 80px;
  text-align: center;
  line-height: 80px;
  border: 1px dashed #1890ff;
  color: #1890ff;
  background-color: #edf4fb;
}
</style>
